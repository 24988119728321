<template>
  <MainLayout>
    <main class="min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200">
      <!-- Hero Section -->
      <header
        class="relative min-h-screen flex items-center justify-center overflow-hidden bg-gradient-to-br from-primary-500 to-secondary-500">
        <div class="absolute inset-0 bg-black opacity-50"></div>
        <div class="container mx-auto px-6 relative z-10">
          <div class="text-center" role="banner">
            <h1 class="text-5xl md:text-7xl font-bold mb-4 animate-fade-in-down text-white font-heading">
              Luis Jose Arias Reyes
            </h1>
            <h2 class="text-2xl md:text-4xl mb-6 animate-fade-in-up text-secondary-200 font-heading">
              Ingeniero en Sistemas y Redes Informáticas
            </h2>
            <p class="text-xl mb-8 animate-fade-in text-white max-w-2xl mx-auto font-mono">
              Creando experiencias web innovadoras y eficientes
            </p>
            <div class="flex justify-center space-x-4 mb-8">
              <a href="/cv.pdf"
                class="inline-block bg-white text-primary-600 hover:bg-primary-100 font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 shadow-lg">
                Descargar CV
              </a>
              <a @click="scrollToSection('featured-projects')"
                class="inline-block bg-secondary-500 text-white hover:bg-secondary-600 font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 shadow-lg cursor-pointer">
                Ver Proyectos
              </a>
            </div>
            <div class="flex justify-center space-x-6">
              <a href="https://github.com/LuisC4" target="_blank"
                class="text-white hover:text-secondary-300 transition duration-300" aria-label="GitHub">
                <GithubIcon size="32" />
              </a>
              <a href="https://www.linkedin.com/in/luis-jose-arias-reyes-948507311/" target="_blank"
                class="text-white hover:text-secondary-300 transition duration-300" aria-label="LinkedIn">
                <LinkedinIcon size="32" />
              </a>
              <a href="https://x.com/Luis78791" target="_blank"
                class="text-white hover:text-secondary-300 transition duration-300" aria-label="Twitter">
                <TwitterIcon size="32" />
              </a>
            </div>
          </div>
        </div>
        <div class="absolute bottom-0 left-0 right-0 text-center pb-8">
          <button @click="scrollToSection('aboutMe')"
            class="inline-block rounded-full bg-opacity-20 hover:bg-opacity-30 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
            aria-label="Desplazarse a la siguiente sección">
            <ChevronsDown size="48" class="text-white animate-bounce" />
          </button>
        </div>
      </header>

      <!-- About Me Section -->
      <AboutMe />
      <!-- Skills Section -->
      <SkillsList />
      <!-- Main Projects Section -->
      <MainProyects />
      <!-- Call to Action Section -->
      <CallToAction />
    </main>
  </MainLayout>
</template>

<script setup>
import MainLayout from "@/layouts/MainLayout.vue";
import MainProyects from "@/views/MainProyects.vue";
import SkillsList from "@/views/SkillsList.vue";
import CallToAction from "@/views/CallToAction.vue";
import AboutMe from "@/views/AboutMe.vue";
import {
  GithubIcon,
  LinkedinIcon,
  TwitterIcon,
  ChevronsDown,
} from "lucide-vue-next";

const scrollToSection = (section) => {
  document.getElementById(section).scrollIntoView({ behavior: "smooth" });
};

</script>

<style scoped>
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-fade-in-down {
  animation: fadeInDown 1s ease-out;
}

.animate-fade-in-up {
  animation: fadeInUp 1s ease-out;
}

.animate-fade-in {
  animation: fadeIn 1s ease-out;
}
</style>